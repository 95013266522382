import { useContext, useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import GulagContext from '../../context/gulag'
import { ViewsEnum } from './types'
import TaskList from './TaskList'

function a11yProps(view: ViewsEnum) {
  return {
    id: `simple-tab-${view}`,
    'aria-controls': `simple-tabpanel-${view}`,
  };
}

interface TaskListTabsProps {
  view: ViewsEnum
  setView: (newValue: ViewsEnum) => void
}

export default function TaskListTabs() {
  const [view, setView] = useState<ViewsEnum>(ViewsEnum.MyOpen)
  const gulag = useContext(GulagContext);
  const handleChange = (event: React.SyntheticEvent, newValue: ViewsEnum) => {
    setView(newValue);
  };
  if (gulag === null) { throw new Error('Gulag is undefined.') }
  return (
    <>
      <br />
      <br />
      <br />
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={view} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="My Tasks" {...a11yProps(ViewsEnum.MyOpen)} />
          <Tab label="All Tasks" {...a11yProps(ViewsEnum.MyOpen)} />
          <Tab label="Completed" {...a11yProps(ViewsEnum.MyOpen)} />
        </Tabs>
      </Box>
      <TaskList view={view} />
    </>
  )
}
