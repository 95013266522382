import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useContext } from 'react';
import CabinIcon from '@mui/icons-material/Cabin';
import { blue } from '@mui/material/colors';

import { useGetSentencesForSelfQuery, useGetGulagsQuery } from '../../services/gulag';
import { Sentence, Gulag } from '../../types';
import GulagContext from '../../context/gulag'
import NavigationContext from '../../context/navigation'

interface GulagAndSentence {
  gulag: Gulag
  sentence: Sentence
}


interface SentenceOptionProps {
  handleListItemClick: (gulagAndSentence: GulagAndSentence) => void
  sentence: Sentence
}

function SentenceOption({ handleListItemClick, sentence }: SentenceOptionProps) {
  const { data: gulags = [], isLoading } = useGetGulagsQuery(null, { pollingInterval: 300000 })

  const gulag = gulags.find(({ id }) => id === sentence.gulag)
  if (gulag === null || gulag === undefined) { return <li>Error</li> }
  return (
    <ListItem button onClick={() => handleListItemClick({ gulag, sentence })} key={sentence.id}>
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
          <CabinIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={gulag.name} secondary={gulag.location} />
    </ListItem>
  )
}

interface GulagSelectorDialogProps {
  handleClose: () => void
  handleListItemClick: (gulagAndSentence: GulagAndSentence) => void
  sentences: Sentence[]
}

function GulagSelectorDialog({
  sentences,
  handleClose,
  handleListItemClick
}: GulagSelectorDialogProps) {
  const open = true;
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={fullScreen}
    >
      <DialogTitle>Select a Gulag</DialogTitle>
      <List sx={{ pt: 0 }}>
        {sentences.map((sentence) => (
          <SentenceOption
            sentence={sentence}
            key={sentence.id}
            handleListItemClick={handleListItemClick}
          />
        ))}
      </List>
    </Dialog>
  )
}

interface ModalGulagSelector {
  selectGulag: (gulagAndSentence: GulagAndSentence) => void
}

export default function ModalGulagSelector({ selectGulag }: ModalGulagSelector) {
  const { data: sentences = [], isLoading, error } = useGetSentencesForSelfQuery(null, { pollingInterval: 300000 });
  if (error && 'status' in error && error.status === 401) {
    window.location.href = '/accounts/login'
  }
  const gulag = useContext(GulagContext);
  if (gulag !== null) { return null }
  return (
    <GulagSelectorDialog sentences={sentences} handleClose={() => {}} handleListItemClick={selectGulag} />
  )
}
