import { useContext } from 'react'

import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

import TaskUnpack from '../../context/lookups/TaskUnpack'
import JobToParts from '../../context/lookups/JobToParts'
import JobPartsContext from '../../context/jobParts'
import JobName from '../../snippets/job/JobName'
import UserName from '../../snippets/user/UserName'
import TaskStatus from '../../snippets/task/TaskStatus'
import TaskIcon from '../../snippets/task/TaskIcon'
import TaskProcess from '../../snippets/task/TaskProcess'
import JobCredits from '../../snippets/job/JobCredits'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

import DoneButton from './DoneButton'
import SkipButton from './SkipButton'
import GrabButton from './GrabButton'
import AwardManager from './AwardManager'

function JobParts() {
  const jobParts = useContext(JobPartsContext)
  if (!jobParts) { return null }
  return (
    <>
      {jobParts.map(part => (
        <ListItem disableGutters key={part.id}>
          <ListItemIcon>
            <ArrowCircleRightOutlinedIcon />
          </ListItemIcon>
          <ListItemText>{part.name}</ListItemText>
        </ListItem>
      ))}
    </>
  )
}

export default function TaskViewContent({ close }: { close: () => void}) {
  return (
    <TaskUnpack>
      <DialogTitle><JobName /></DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'space-between'}}>
          <Typography><UserName /></Typography>
          <Typography><TaskStatus /></Typography>
        </DialogContentText>
        <List>
          <JobToParts>
            <JobParts />
          </JobToParts>
          <ListItem disableGutters>
            <ListItemIcon>
              <EmojiEventsOutlinedIcon />
            </ListItemIcon>
            <ListItemText>
              <JobCredits /> credits
            </ListItemText>
          </ListItem>
        </List>
        <AwardManager />
        <Accordion disableGutters sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Job Allocation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{overflow: 'auto'}}>
              <TaskProcess />
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button sx={{ marginRight: 'auto'}} onClick={close}>Close</Button>
        <SkipButton handleClose={close} />
        <DoneButton handleClose={close} />
        <GrabButton handleClose={close} />
      </DialogActions>
    </TaskUnpack>
  )
}
