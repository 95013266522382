import { createContext, useState } from 'react'

export interface NavLocation {
  gulagId?: number
  sentenceId?: number
  taskId?: number
}

export interface NavLocationContext {
  location: NavLocation
  setLocation: (newLocation: NavLocation) => void
}

const NavigationContext = createContext<NavLocationContext>({ location: {}, setLocation: () => {}})

export default NavigationContext

interface NavigationContextProviderProps {
  children: JSX.Element | JSX.Element[] | null
}

export function NavigationContextProvider({ children }: NavigationContextProviderProps) {
  const [location, setLocation] = useState({})

  return (
    <NavigationContext.Provider value={{location, setLocation }}>
      {children}
    </NavigationContext.Provider>
  )
}
