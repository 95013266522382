import React from 'react';
import { Provider } from 'react-redux'
import './App.css';
import { store } from './store'
import Main from './ux/main'
import { NavigationContextProvider } from './context/navigation'


function App() {

  return (
    <Provider store={store}>
      <NavigationContextProvider>
        <Main />
      </NavigationContextProvider>
    </Provider>
  );
}

export default App;
