import { useContext } from 'react'
import TaskContext from '../task'
import GulagContext from '../gulag'
import SentenceContext from '../sentence'
import { useGetSentencesForGulagQuery } from '../../services/gulag'

interface TaskToSentenceProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function TaskToSentence({ children }: TaskToSentenceProps) {
  const gulag = useContext(GulagContext)
  const task = useContext(TaskContext)
  if (gulag === null) { throw new Error('Cannot use outside gulag context') }
  const { data: sentences = [], isLoading } = useGetSentencesForGulagQuery(gulag.id, { pollingInterval: 300000 })
  const taskSentence = task === null ? null : task.sentence;
  const sentence = sentences.find(({ id: sentenceId }) => sentenceId === taskSentence)
  return (
    <SentenceContext.Provider value={sentence || null}>
      {children}
    </SentenceContext.Provider>
  )
}
