import { useContext } from 'react'
import { Skeleton } from '@mui/material'
import TaskContext from '../../context/task'


export default function TaskStatus() {
  const task = useContext(TaskContext);
  if (task === null) { return <Skeleton variant="rectangular" width={210} /> }
  return (
    <pre>
      {task.process}
    </pre>
  )
}
