import { useContext, useState } from 'react'
import {
  IconButton,
  Button,
  Badge,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Notification } from '../../types'
import NotificationsContext from '../../context/notifications'
import SelfSentenceContext from '../../context/selfSentence'
import { useMarkReadMutation, useMarkAllAsReadMutation } from '../../services/gulag'


export function NotificationItem({ value, handleMarkRead }: { value: Notification, handleMarkRead: () => void }) {
  return (
    <ListItemButton onClick={handleMarkRead}>
      <ListItemText primary={value.title} secondary={value.content}/>
    </ListItemButton>
  )
}

export default function Notifications() {
  const notifications = useContext(NotificationsContext)
  const sentence = useContext(SelfSentenceContext)
  const [open, setOpen] = useState(false)
  const [markRead] = useMarkReadMutation()
  const [markAllAsRead] = useMarkAllAsReadMutation()
  const count = (notifications || []).length;
  if (count === 0) { return null }
  return (
    <>
      <IconButton onClick={() => setOpen(true)} color="inherit">
        <Badge badgeContent={count.toString()} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 280,
            maxWidth: 'calc(100vw - 2rem)'
          },
        }}
      >
        {sentence && <Button onClick={() => markAllAsRead(sentence.id)}>Mark all as read</Button>}
        <List>
          {
            (notifications || [])
              .map(notification => (
                <NotificationItem
                  key={notification.id}
                  value={notification}
                  handleMarkRead={() => markRead(notification.id)}
                />
              ))
          }
        </List>
      </Drawer>
    </>
  )
}
