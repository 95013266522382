import { useContext } from 'react'
import GulagContext from '../gulag'
import { useGetGulagsQuery } from '../../services/gulag'

interface GulagByIdProps {
  id: number | null | undefined
  children: JSX.Element | JSX.Element[] | null
}

export default function GulagById({ id, children }: GulagByIdProps) {
  const { data: gulags = [], isLoading } = useGetGulagsQuery(null, { pollingInterval: 300000 })
  const gulag = gulags.find(({ id: gulagId }) => gulagId === id)

  return (
    <GulagContext.Provider value={gulag || null}>
      {children}
    </GulagContext.Provider>
  )
}
