import { useContext, useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material'

import GulagContext from '../../context/gulag'
import JobForm from './JobForm'

import { useCreateJobMutation } from '../../services/gulag'
import { newJob, EditedJob } from '../../types'

interface JobCreatorProps {
  goToEdit: (id: number) => void
  onClose: () => void
}

export default function JobCreator({ goToEdit, onClose }: JobCreatorProps) {
  const gulag = useContext(GulagContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  if (gulag === null) { throw new Error('Cannot use outside gulag context') }
  const [job, setJob] = useState(newJob(gulag.id))
  const [save, saveResult] = useCreateJobMutation()

  async function handleSave(value: EditedJob) {
    if (gulag === null) { throw new Error('Cannot use outside gulag context') }
    const result = await save(value).unwrap()
    setJob(newJob(gulag.id))
    goToEdit(result.id)
  }

  return (
    <Dialog open fullScreen={fullScreen}>
      <DialogTitle>
        Create new regular job
      </DialogTitle>
      <DialogContent>
        <JobForm
          isLoading={saveResult.isLoading}
          initValue={job}
          onSave={handleSave}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
