import TaskToJob from './TaskToJob'
import TaskToResolution from './TaskToResolution'
import TaskToSentence from './TaskToSentence'
import SentenceToUser from './SentenceToUser'
import TaskToCommendations from './TaskToCommendations'

interface TaskUnpackProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function TaskUnpack({ children }: TaskUnpackProps) {
  return (
    <TaskToJob>
      <TaskToCommendations>
        <TaskToResolution>
          <TaskToSentence>
            <SentenceToUser>
              {children}
            </SentenceToUser>
          </TaskToSentence>
        </TaskToResolution>
      </TaskToCommendations>
    </TaskToJob>
  )
}
