import { useContext } from 'react'
import NotificationsContext from '../notifications'
import SelfSentenceContext from '../selfSentence'
import { Sentence } from '../../types'
import { useGetUnreadQuery } from '../../services/gulag'

interface ConnectedProps {
  selfSentence: Sentence
  children: JSX.Element | JSX.Element[] | null
}

function Connected({ selfSentence, children }: ConnectedProps) {
  const { data = [] } = useGetUnreadQuery(selfSentence.id, { pollingInterval: 10000 })
  return (
    <NotificationsContext.Provider value={data}>
      {children}
    </NotificationsContext.Provider>
  )
}

interface SelfSentenceByIdProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function SelfSentenceById({ children }: SelfSentenceByIdProps) {
  const selfSentence = useContext(SelfSentenceContext)
  if (selfSentence === null) {
    return <>{children}</>
  }
  return <Connected selfSentence={selfSentence}>{children}</Connected>
}
