import { useContext } from 'react'
import { Skeleton, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import useDateTime from '../../utils/useDateTime'
import TaskContext from '../../context/task'
import ResolutionName from '../resolution/ResolutionName'
import { Task } from '../../types'


function cal(time: DateTime) {
  const now = DateTime.now()
  const days = time.diff(now, ["days"]).days
  if (days < 2) {
    return time.toRelative()
  }
  return time.toFormat("dd.MM.yyyy")
}

function Status({ task }: { task: Task }) {
  const { absolute: due, relative: fromDue } = useDateTime(task.due)
  const { absolute: expires, relative: fromExpires } = useDateTime(task.expires)
  const { absolute: assigned, relative: fromAssigned } = useDateTime(task.assigned)
  const { absolute: completed, relative: fromCompleted } = useDateTime(task.completed || "1970-01-01")

  if (task.completed === null) {
    if (fromAssigned.valueOf() > 0) {
      return <>Begins {cal(assigned)}</>
    }
    if (fromDue.valueOf() < 0) {
      return (
        <span
          style={{ color: 'red', opacity: 0.7}}
        >
          Overdue - expires {cal(expires)}
        </span>
      )
    }
    return <>Due {cal(due)}</>
  }
  return <><ResolutionName /> {completed.toRelative()}</>
}

export default function TaskStatus() {
  const task = useContext(TaskContext);
  if (task === null) { return <Skeleton variant="rectangular" width={210} /> }

  return <Status task={task} />
}
