import {
  Typography,
  List,
  ListItem,
  TextField,
  IconButton,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useState } from 'react'
import {
  useAddJobPartMutation,
  useDeleteJobPartMutation,
  useUpdateJobPartMutation,
} from '../../services/gulag'
import { Job } from '../../types'

import JobPartsContext from '../../context/jobParts'
import JobContext from '../../context/job'

function JobPartEditor({ id, value, job }: { id: number, value: string, job: Job }) {
  const [deletePart, deletePartResult] = useDeleteJobPartMutation()
  const [updatePart, updatePartResult] = useUpdateJobPartMutation()
  const [newName, setNewName] = useState<string>(value)
  function handleChange() {
    updatePart({ id, name: newName, job_id: job.id })
  }
  function handleDelete() {
    deletePart(id)
  }
  return (
    <ListItem disableGutters>
      <TextField
        fullWidth
        value={newName}
        onChange={e => setNewName(e.target.value)}
        InputProps={{
          endAdornment: (
            <>
              {newName !== value && <IconButton onClick={handleChange} >
                <SaveIcon />
              </IconButton>}
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </>
          )
        }}
      />
    </ListItem>
  )
}

export default function JobPartsManager() {
  const job = useContext(JobContext)
  const jobParts = useContext(JobPartsContext)
  const [addJob, addJobStatus] = useAddJobPartMutation()
  const [newJobName, setNewJobName] = useState('')
  function handleNew() {
    if (job === null) { return }
    addJob({ job_id: job.id, name: newJobName })
    setNewJobName('')
  }
  if (job === null) { return null }
  return (
    <>
      <br />
      <Typography variant="h6">Job parts</Typography>
      <List>
        {
          (jobParts || []).map(({ id, name }) => (
            <JobPartEditor key={id} value={name} id={id} job={job} />
          ))
        }
        <ListItem disableGutters>
          <TextField
            fullWidth
            value={newJobName}
            onChange={e => setNewJobName(e.target.value)}
            InputProps={{
              endAdornment: newJobName !== '' && (
                <>
                  <IconButton onClick={handleNew} >
                    <SaveIcon />
                  </IconButton>
                </>
              )
            }}
          />
        </ListItem>
      </List>
    </>
  )
}
