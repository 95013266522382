import { useContext, useState } from 'react'

import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material'

import JobEditor from './JobEditor'
import JobCreator from './JobCreator'

import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';

import AddIcon from '@mui/icons-material/Add';

import JobsContext from '../../context/jobs'
import JobById from '../../context/lookups/JobById'

export default function JobsList() {
  const jobs = useContext(JobsContext)
  const [editId, setEditId] = useState<null | number>(null)
  const [showCreate, setShowCreate] = useState<boolean>(false)
  if (jobs === null) { return null }
  return (
    <>
      <JobById id={editId}>
        <JobEditor onClose={() => setEditId(null)} />
      </JobById>
      {showCreate && <JobCreator onClose={() => setShowCreate(false)} goToEdit={id => {setShowCreate(false); setEditId(id);}} />}
      <List
        component="nav"
        subheader={
          <ListSubheader>
            Jobs
          </ListSubheader>
        }
      >
        {jobs.map((job) => (
          <ListItemButton onClick={() => setEditId(job.id)} key={job.id}>
            <ListItemIcon>
              {job.active ? <SyncIcon /> : <SyncDisabledIcon />}
            </ListItemIcon>
            <ListItemText>
              {job.name}
            </ListItemText>
          </ListItemButton>
        ))}
        <Divider sx={{ opacity: 0.25 }}/>
        <ListItemButton onClick={() => setShowCreate(true)}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText>
            Create new
          </ListItemText>
        </ListItemButton>
        <Divider />
      </List>
    </>
  )
}
