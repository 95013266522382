import { useContext } from 'react'

import CommendationsContext from '../commendations'
import TaskContext from '../task'
import { Task } from '../../types'
import {
  useGetTaskCommendationsQuery
} from '../../services/gulag'

interface ConnectedTaskToCommendationsProps {
  children: JSX.Element | JSX.Element[] | null
  task: Task
}

function ConnectedTaskToCommendations({ children, task }: ConnectedTaskToCommendationsProps) {
  const { data = [] } = useGetTaskCommendationsQuery(task.id, { pollingInterval: 300000 })
  return (
    <CommendationsContext.Provider value={data}>
      {children}
    </CommendationsContext.Provider>
  )
}

interface TaskToCommendationsProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function TaskToCommendations({ children }: TaskToCommendationsProps) {
  const task = useContext(TaskContext);

  if (task === null) {
    return (
      <CommendationsContext.Provider value={null}>
        {children}
      </CommendationsContext.Provider>
    )
  }

  return (
    <ConnectedTaskToCommendations task={task}>{children}</ConnectedTaskToCommendations>
  )
}
