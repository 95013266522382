import { useContext } from 'react'
import GulagContext from '../gulag'
import SentenceContext from '../sentence'
import { useGetSentencesForGulagQuery } from '../../services/gulag'

interface SentenceByIdProps {
  id: number | null | undefined
  children: JSX.Element | JSX.Element[] | null
}

export default function SentenceById({ id, children }: SentenceByIdProps) {
  const gulag = useContext(GulagContext)
  if (gulag === null) { throw new Error('Cannot use outside gulag') }
  const { data: sentences = [], isLoading } = useGetSentencesForGulagQuery(gulag.id, { pollingInterval: 300000 })
  const sentence = sentences.find(({ id: sentenceId }) => sentenceId === id)
  return (
    <SentenceContext.Provider value={sentence || null}>
      {children}
    </SentenceContext.Provider>
  )
}
