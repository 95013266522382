import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
} from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useContext } from 'react';
import GulagContext from '../../context/gulag'
import Notifications from './Notifications'

export default function MainAppBar({ openDrawer }: { openDrawer: () => void}) {
  const gulag = useContext(GulagContext);
  return (
    <AppBar position="absolute">
      <Toolbar
        sx={{
          pr: '24px',
        }}
      >
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={openDrawer}
            sx={{ mr: 1 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {gulag ? gulag.name : 'Todo'}
        </Typography>
        <Notifications />
      </Toolbar>
    </AppBar>
  )
}
