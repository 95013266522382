import { useContext } from 'react'

import TaskContext from '../task'
import JobById from './JobById'

interface TaskToJobProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function TaskToJob({ children }: TaskToJobProps) {
  const task = useContext(TaskContext);

  return <JobById id={task && task.job}>{children}</JobById>
}
