import { SyntheticEvent } from 'react'
import { Autocomplete, TextField, SxProps } from '@mui/material'

const months = [
  { name: 'January', days: 31 },
  { name: 'February', days: 28 },
  { name: 'March', days: 31 },
  { name: 'April', days: 30 },
  { name: 'May', days: 31 },
  { name: 'June', days: 30 },
  { name: 'July', days: 31 },
  { name: 'August', days: 31 },
  { name: 'September', days: 30 },
  { name: 'October', days: 31 },
  { name: 'November', days: 30 },
  { name: 'December', days: 31 },
]

const suffix = ['st', 'nd', 'rd']

function pad2(val: number) {
  let s = val.toString()
  while (s.length < 2) {
    s = `0${s}`
  }
  return s
}

interface Day {
  name: string
  monthName: string
  isoFormat: string
}

const days: Day[] = months
  .flatMap((month, monthIdx) => (
    [...new Array(month.days)]
      .map((_, dayIdx) => ({
        name: `${dayIdx + 1}${suffix[dayIdx] || 'th'} ${month.name}`,
        monthName: month.name,
        isoFormat: `1970-${pad2(dayIdx + 1)}-${pad2(monthIdx + 1)}`
      }))
  ))

interface AnnualDatePickerProps {
  sx?: SxProps
  name: string
  value: string | null
  onChange: (key: string, newValue: string | null) => void
  label: string
}

export default function AnnualDatePicker({
  name,
  value,
  onChange,
  label,
  sx = {},
}: AnnualDatePickerProps) {
  return (
    <Autocomplete
      sx={sx}
      options={days}
      value={value === null ? null : days.find(({ isoFormat }) => isoFormat === value)}
      groupBy={(option) => option.monthName}
      getOptionLabel={(option) => option.name}
      onChange={(_: SyntheticEvent, newValue: Day | null) => onChange(name, newValue === null ? null :newValue.isoFormat)}
      renderInput={(params) => <TextField {...params} label={label} name={name} placeholder="" />}
    />
  )
}
