import { useContext } from 'react'

import ResolutionsContext from '../resolutions'
import GulagContext from '../gulag'

import { useGetResolutionsQuery } from '../../services/gulag'

interface GulagToResolutionsProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function GulagToResolutions({ children }: GulagToResolutionsProps) {
  const gulag = useContext(GulagContext)
  if (gulag === null) { throw new Error('Cannot use outside gulag context') }
  const {data: resolutions = []} = useGetResolutionsQuery(gulag.id, { pollingInterval: 300000 })
  return (
    <ResolutionsContext.Provider value={resolutions}>
      {children}
    </ResolutionsContext.Provider>
  )
}
