import { useEffect, useState, useCallback, ChangeEvent } from 'react'
import {
  TextField,
  Box,
  MenuItem,
  Divider,
  Button,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { Job, EditedJob, toEditedJob } from '../../types'
import AnnualDatePicker from './AnnualDatePicker'

interface JobFormProps {
  initValue: Job | EditedJob
  onSave: (value: EditedJob) => void
  isLoading: boolean
}

export default function JobForm({ initValue, onSave, isLoading = false }: JobFormProps) {
  const [value, setValue] = useState<EditedJob>(toEditedJob(initValue))
  useEffect(() => {
    setValue(toEditedJob(initValue))
  }, [initValue])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [event.target.name]: event.target.value})
  }, [value, setValue])

  const didChange = JSON.stringify(toEditedJob(initValue)) !== JSON.stringify(toEditedJob(value))

  return (
    <>
      <Box sx={{ pt: 1, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          sx={{ flexGrow: 1, flexBasis: '70%' }}
          label="Name"
          value={value.name}
          name="name"
          onChange={handleChange}
        />
        <TextField
          sx={{ flexGrow: 1, flexBasis: '10%', minWidth: '6rem' }}
          select
          label="Active"
          value={value.active}
          name="active"
          onChange={handleChange}
        >
          <MenuItem value="true">Active</MenuItem>
          <MenuItem value="false">Inactive</MenuItem>
        </TextField>
        <TextField
          sx={{ flexGrow: 1, flexBasis: '10%', minWidth: '6rem' }}
          type="number"
          label="Credits"
          value={value.credits}
          name="credits"
          onChange={handleChange}
        />
      </Box>
      <Typography sx={{ pt: 2, pb: 1 }}>Scheduling</Typography>
      <Box sx={{ pt: 1, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          sx={{ flexGrow: 1 }}
          type="number"
          label="Every"
          value={value.frequency}
          name="frequency"
          onChange={handleChange}
        />
        <TextField
          sx={{ flexGrow: 1 }}
          select
          label="Frequency"
          value={value.frequency_unit}
          name="frequency_unit"
          onChange={handleChange}
        >
          <MenuItem value="day">day</MenuItem>
          <MenuItem value="week">week</MenuItem>
          <MenuItem value="month">month</MenuItem>
          <MenuItem value="year">year</MenuItem>
        </TextField>
        <TextField
          sx={{ flexGrow: 1 }}
          type="number"
          label="Plan ahead"
          value={value.plan_ahead}
          name="plan_ahead"
          onChange={handleChange}
        />
        <AnnualDatePicker
          sx={{ flexGrow: 1, flexBasis: '40%' }}
          label="Season begins"
          name="season_begins"
          value={value.season_begins || null}
          onChange={(key, newValue) => setValue({...value, [key]: newValue})}
        />
        <AnnualDatePicker
          sx={{ flexGrow: 1, flexBasis: '40%' }}
          label="Season ends"
          name="season_ends"
          value={value.season_ends || null}
          onChange={(key, newValue) => setValue({...value, [key]: newValue})}
        />
        <Box sx={{ width: '100%' }}>
          Schedule on:
          <Box sx={{ display: 'flex', gap: 1, width: '100%', justifyContent: 'center' }}>
            {
              ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
              .map((day, idx) => ({
                day,
                field: `weekday_${idx + 1}_${day.toLowerCase()}` as keyof typeof value,
                letter: day[0]
              }))
              .map(({ day, field, letter }) => (
                <Button
                  sx={{ minWidth: 0, width: '2rem', height: '2rem', borderRadius: '1rem' }}
                  key={day}
                  variant={value[field] ? 'contained' : 'text'}
                  onClick={() => setValue({...value, [field]: !value[field]})}
                >
                  {day[0]}
                </Button>
              ))
            }
          </Box>
        </Box>
      </Box>
      {didChange && (
        <Box sx={{ pt: 1, display: 'flex', gap: 2, justifyContent: 'end' }}>
          <LoadingButton loading={isLoading} variant="contained" onClick={() => onSave(value)}>Save</LoadingButton>
        </Box>
      )}
    </>
  )
}
