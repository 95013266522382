import LoadingButton from '@mui/lab/LoadingButton';
import { useContext } from 'react'
import { DateTime } from 'luxon'
import { Task } from '../../types'
import SelfSentenceContext from '../../context/selfSentence'
import TaskContext from '../../context/task'
import ResolutionsContext from '../../context/resolutions'
import { useGrabTaskMutation } from '../../services/gulag'


export default function GrabButton({ handleClose }: { handleClose: () => void }) {
  const task = useContext(TaskContext)
  const selfSentence = useContext(SelfSentenceContext)
  const [grab, result] = useGrabTaskMutation()
  if (!task || !selfSentence) { return null }
  if (task.sentence === selfSentence.id) { return null }
  if (task.resolution !== null) { return null }
  const now = DateTime.now()
  const due = DateTime.fromISO(task.due)
  if (now > due) { return null }

  return (
    <LoadingButton
      onClick={() => grab(task.id)}
      variant="contained"
      disabled={result.status !== 'uninitialized'}
      loading={result.status === 'pending'}
    >
      Grab
    </LoadingButton>
  )

}
