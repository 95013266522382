import { useContext } from 'react'
import TaskContext from '../task'
import { useGetTaskQuery } from '../../services/gulag'

interface TaskByIdConnectedProps {
  id: number
  children: JSX.Element | JSX.Element[] | null
}

function TaskByIdConnected({ id, children }: TaskByIdConnectedProps) {
  const { data: task, isLoading } = useGetTaskQuery(id, { pollingInterval: 300000 })

  return (
    <TaskContext.Provider value={task || null}>
      {children}
    </TaskContext.Provider>
  )
}

interface TaskByIdProps {
  id: number | null | undefined
  children: JSX.Element | JSX.Element[] | null
}

export default function TaskById({ id, children }: TaskByIdProps) {
  if (id === null || id === undefined) {
    return (
      <TaskContext.Provider value={null}>
        {children}
      </TaskContext.Provider>
    )
  }
  return <TaskByIdConnected id={id} children={children} />
}
