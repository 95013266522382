import { useContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';

import JobContext from '../../context/job'
import JobToParts from '../../context/lookups/JobToParts'
import JobForm from './JobForm'
import JobPartsManager from './JobPartsManager'
import JobExemptionsManager from './JobExemptionsManager'

import {
  useSaveJobMutation,
  useDeleteJobMutation,
  useReassignJobMutation,
} from '../../services/gulag'


export default function JobEditor({ onClose }: { onClose: () => void}) {
  const job = useContext(JobContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [save, saveResult] = useSaveJobMutation()
  const [reassign, reassignResult] = useReassignJobMutation()
  const [deleteJob, deleteJobResult] = useDeleteJobMutation()
  if (job === null) { return null }
  return (
    <Dialog open fullScreen={fullScreen}>
      <DialogTitle>
        Edit Job: {job.name}
      </DialogTitle>
      <DialogContent>
        <JobForm isLoading={saveResult.isLoading} initValue={job} onSave={value => save(value)}/>
        <JobToParts>
          <JobPartsManager />
        </JobToParts>
        <JobExemptionsManager />
        <Box sx={{mt: 3, gap: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography>Remove and then re-assign all open jobs:</Typography>
          <LoadingButton loading={reassignResult.isLoading} onClick={() => reassign(job.id)} sx={{ whiteSpace: 'nowrap' }}>Re-assign</LoadingButton>
        </Box>
        <Box sx={{mt: 3, gap: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography>Delete this job, and all records of any tasks. You probably don't want to do this.</Typography>
          <LoadingButton loading={deleteJobResult.isLoading} onClick={() => deleteJob(job.id)} sx={{ whiteSpace: 'nowrap' }}>Delete</LoadingButton>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
