import {
  Drawer,
  Toolbar,
  Divider,
  Typography,
} from '@mui/material'
import GulagToJobs from '../../context/lookups/GulagToJobs'
import DrawerGulagSelector, { GulagAndSentence } from '../gulag-selector/DrawerGulagSelector'
import JobsList from '../management/JobsList'

const drawerWidth = 280;

interface MainDrawerProps {
  open: boolean
  onClose: () => void
  selectGulag: (val: GulagAndSentence) => void
}

export default function MainDrawer({ open, onClose, selectGulag }: MainDrawerProps) {
  return (
    <Drawer
      variant="temporary"
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          maxWidth: 'calc(100vw - 2rem)'
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DrawerGulagSelector selectGulag={selectGulag} />
      <Divider />
      <GulagToJobs>
        <JobsList />
      </GulagToJobs>
    </Drawer>
  )
}
