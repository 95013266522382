import { useContext } from 'react'
import ResolutionContext from '../../context/resolution'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WatchOffIcon from '@mui/icons-material/WatchOff';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { SvgIconProps } from '@mui/material'


export default function ResolutionIcon({ color }: SvgIconProps) {
  const resolution = useContext(ResolutionContext)
  if (resolution === null) { return <HourglassEmptyIcon /> }
  if (resolution.credits_awarded) {
    if (resolution.streak_protected) {
      return <TaskAltIcon color={color} />
    }
    return <WatchOffIcon color={color} />
  }
  if (resolution.streak_protected) {
    return <ThumbUpOffAltIcon color={color} />
  }
  return <CancelOutlinedIcon color={color} />

}
