import LoadingButton from '@mui/lab/LoadingButton';
import { useContext, useCallback } from 'react'
import { DateTime } from 'luxon'
import useDateTime from '../../utils/useDateTime'
import { useResolveTaskMutation } from '../../services/gulag'
import { Task, Resolution } from '../../types'
import SelfSentenceContext from '../../context/selfSentence'
import TaskContext from '../../context/task'
import JobContext from '../../context/job'
import ResolutionsContext from '../../context/resolutions'


export default function DoneButton({ handleClose }: { handleClose: () => void }) {
  const [resolve, result] = useResolveTaskMutation()

  const task = useContext(TaskContext)
  const job = useContext(JobContext)

  if (task === null) { throw new Error('Cannot be used outside task context') }

  const { relative: assignedRelative } = useDateTime(task.assigned)

  const resolutions = useContext(ResolutionsContext)
  const selfSentence = useContext(SelfSentenceContext)
  const handleClick = useCallback(() => {
    if (!task || !resolutions) { return }
    const now = DateTime.now()
    const due = DateTime.fromISO(task.due)
    const resolution = resolutions.find((resolution) => {
      if (now <= due) { return resolution.credits_awarded && resolution.streak_protected }
      return resolution.credits_awarded && !resolution.streak_protected
    })
    if (!resolution) { return }
    resolve({ resolutionId: resolution.id, taskId: task.id })
  }, [task])
  if (!task || !selfSentence) { return null }
  if (task.sentence !== selfSentence.id) { return null }
  if (task.resolution !== null) { return null }
  if (job === null) { return null }
  if (job.plan_ahead > 1 && assignedRelative.valueOf() > 0) { return null }

  if (result.status === 'fulfilled') { handleClose() }

  return (
    <LoadingButton
      color="success"
      variant="contained"
      onClick={handleClick}
      disabled={result.status !== 'uninitialized'}
      loading={result.status === 'pending'}
    >
      Done
    </LoadingButton>
  )
}
