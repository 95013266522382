import { useContext } from 'react'
import SelfSentenceContext from '../selfSentence'
import { useGetSentencesForSelfQuery } from '../../services/gulag'

interface SelfSentenceByIdProps {
  id: number | null | undefined
  children: JSX.Element | JSX.Element[] | null
}

export default function SelfSentenceById({ id, children }: SelfSentenceByIdProps) {
  const { data: sentences = [], isLoading } = useGetSentencesForSelfQuery(null, { pollingInterval: 300000 })
  const sentence = sentences.find(({ id: sentenceId }) => sentenceId === id)
  return (
    <SelfSentenceContext.Provider value={sentence || null}>
      {children}
    </SelfSentenceContext.Provider>
  )
}
