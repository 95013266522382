import { useContext } from 'react'
import { useGetJobPartsQuery } from '../../services/gulag'
import JobContext from '../job'
import JobPartsContext from '../jobParts'
import { Job } from '../../types'


interface JobToPartsConnectedProps {
  children: JSX.Element | JSX.Element[] | null
  job: Job
}

function JobToPartsConnected({ children, job }: JobToPartsConnectedProps) {
  const { data: parts = [] } = useGetJobPartsQuery(job.id, { pollingInterval: 300000 })
  return (
    <JobPartsContext.Provider value={parts || null}>
      {children}
    </JobPartsContext.Provider>
  )
}

interface JobToPartsProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function JobToParts({ children }: JobToPartsProps) {
  const job = useContext(JobContext);
  if (job === null) {
    return <JobPartsContext.Provider value={null}>{children}</JobPartsContext.Provider>
  }
  return <JobToPartsConnected job={job}>{children}</JobToPartsConnected>
}
