import { useState, useCallback, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MainAppBar from './MainAppBar';
import MainDrawer from './MainDrawer';
import ModalGulagSelector from '../gulag-selector/ModalGulagSelector';
import GulagById from '../../context/lookups/GulagById'
import SelfSentenceToNotifications from '../../context/lookups/SelfSentenceToNotifications'
import NavigationContext from '../../context/navigation'
import SelfSentenceById from '../../context/lookups/SelfSentenceById'
import { Gulag, Sentence } from '../../types'
import GulagRoot from '../gulag-root/GulagRoot'

const mdTheme = createTheme();

interface GulagAndSentence {
  gulag: Gulag
  sentence: Sentence
}

export default function Main() {
  const { location, setLocation } = useContext(NavigationContext)
  const setGulagAndSentence = useCallback(({ sentence, gulag }: GulagAndSentence) => {
    setLocation({ gulagId: gulag.id, sentenceId: sentence.id });
  }, [setLocation])
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  return (
    <ThemeProvider theme={mdTheme}>
      <GulagById id={location.gulagId}>
        <SelfSentenceById id={location.sentenceId}>
          <SelfSentenceToNotifications>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <MainAppBar openDrawer={() => setDrawerOpen(true)}/>
              <MainDrawer
                selectGulag={setGulagAndSentence}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
              />
              <ModalGulagSelector selectGulag={setGulagAndSentence}/>
              <GulagRoot />
            </Box>
          </SelfSentenceToNotifications>
        </SelfSentenceById>
      </GulagById>
    </ThemeProvider>
  )
}
