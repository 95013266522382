import { useContext } from 'react'
import { DateTime } from 'luxon'

import { Skeleton } from '@mui/material'

import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import WarningIcon from '@mui/icons-material/Warning';

import TaskContext from '../../context/task'
import ResolutionIcon from '../resolution/ResolutionIcon'
import EventIcon from '@mui/icons-material/Event';

export default function TaskIcon() {
  const task = useContext(TaskContext);
  if (!task) { return <Skeleton variant="rectangular" width={32} /> }
  if (task.resolution !== null) {
    return <ResolutionIcon color="success" />
  }
  const now = DateTime.now()
  const due = DateTime.fromISO(task.due)
  const expires = DateTime.fromISO(task.expires)

  const daysRemaining = due.diff(now, ["days"]).days

  if (daysRemaining < 0) {
    return <NotificationImportantIcon color="error" />
  }
  if (daysRemaining < 1) {
    return <NotificationsActiveOutlinedIcon color="warning" />
  }
  return <EventIcon />


}
