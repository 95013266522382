import { DateTime } from 'luxon'
import React, {useEffect, useState} from "react";


export default function useDateTime(datetime: string, interval: number = 1000) {

  function getAbsolute() {
    return DateTime.fromISO(datetime)
  }

  function getRelative() {
    const now = DateTime.now()
    return absolute.diff(now)
  }
  const [absolute, setAbsolute] = useState(getAbsolute())
  const [relative, setRelative] = useState(getRelative());

  useEffect(() => {
    const refresh = setInterval(() => {
        setRelative(getRelative());
        setAbsolute(getAbsolute());
    }, interval);
    return () => clearInterval(refresh);
  }, [datetime, interval]);

  return { absolute, relative };
}
