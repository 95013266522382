import { useContext } from 'react'
import SentenceContext from '../sentence'
import GulagContext from '../gulag'
import UserContext from '../user'
import { useGetUsersQuery } from '../../services/gulag'

interface SentenceToUserProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function SentenceToUser({ children }: SentenceToUserProps) {
  const gulag = useContext(GulagContext)
  const sentence = useContext(SentenceContext)
  if (gulag === null) { throw new Error('Cannot use outside gulag context') }
  const { data: users = [], isLoading } = useGetUsersQuery(gulag.id, { pollingInterval: 300000 })
  const sentenceUser = sentence === null ? null : sentence.user;
  const user = users.find(({ id: userId }) => userId === sentenceUser)
  return (
    <UserContext.Provider value={user || null}>
      {children}
    </UserContext.Provider>
  )
}
