import React, { useContext, useCallback } from 'react'
import {
  Dialog,
  useMediaQuery,
  useTheme,
} from '@mui/material'


import TaskContext from '../../context/task'
import NavigationContext from '../../context/navigation'
import TaskViewContent from './TaskViewContent'


export default function TaskView() {
  const { location, setLocation } = useContext(NavigationContext)
  const task = useContext(TaskContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const close = useCallback(() => setLocation({ ...location, taskId: undefined }), [location, setLocation])

  return (
    <Dialog
      open={task !== null}
      onClose={close}
      fullScreen={fullScreen}
    >
      <TaskViewContent close={close} />
    </Dialog>
  )
}
