import { useContext } from 'react'
import { Gulag } from '../../types'
import GulagContext from '../gulag'
import JobsContext from '../jobs'
import { useGetJobsQuery } from '../../services/gulag'

interface GulagToJobsConnectedProps {
  children: JSX.Element | JSX.Element[] | null
  gulag: Gulag
}

function GulagToJobsConnected({ children, gulag }: GulagToJobsConnectedProps) {
  const { data: jobs = [] } = useGetJobsQuery(gulag.id, { pollingInterval: 300000 })
  return (
    <JobsContext.Provider value={jobs}>
      {children}
    </JobsContext.Provider>
  )
}

interface GulagToJobsProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function GulagToJobs({ children }: GulagToJobsProps) {
  const gulag = useContext(GulagContext)
  if (gulag === null) { return <JobsContext.Provider value={null}>{children}</JobsContext.Provider>}
  return <GulagToJobsConnected gulag={gulag} children={children} />
}
