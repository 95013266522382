export interface Sentence {
  id: number
  gulag: number
  user: number
  total_credits: number
}

export interface Gulag {
  id: number
  name: string
  location: string
}

export interface Task {
  id: number
  sentence: number
  resolution: number | null
  due: string
  expires: string
  completed: string | null
  assigned: string
  job: number
  process: string
}

export type FrequencyUnit = 'day' | 'week' | 'month' | 'year'

export interface Job {
  id: number
  name: string
  gulag: number
  credits: number
  plan_ahead: number
  active: boolean
  frequency: number
  frequency_unit: FrequencyUnit
  season_begins: string | null
  season_ends: string | null
  weekday_1_monday: boolean,
  weekday_2_tuesday: boolean,
  weekday_3_wednesday: boolean,
  weekday_4_thursday: boolean,
  weekday_5_friday: boolean,
  weekday_6_saturday: boolean,
  weekday_7_sunday: boolean,
}

export interface EditedJob {
  id?: number
  name: string
  gulag_id: number
  credits: number
  plan_ahead: number
  active: boolean
  frequency: number
  frequency_unit: FrequencyUnit
  season_begins: string | null
  season_ends: string | null
  weekday_1_monday: boolean,
  weekday_2_tuesday: boolean,
  weekday_3_wednesday: boolean,
  weekday_4_thursday: boolean,
  weekday_5_friday: boolean,
  weekday_6_saturday: boolean,
  weekday_7_sunday: boolean,
}

export function newJob(gulagId: number): EditedJob {
  return {
    name: '',
    gulag_id: gulagId,
    credits: 0,
    plan_ahead: 1,
    active: false,
    frequency: 1,
    frequency_unit: 'week',
    season_begins: null,
    season_ends: null,
    weekday_1_monday: true,
    weekday_2_tuesday: true,
    weekday_3_wednesday: true,
    weekday_4_thursday: true,
    weekday_5_friday: true,
    weekday_6_saturday: true,
    weekday_7_sunday: true,
  }
}

export function asBool(input: boolean | string | null | undefined): boolean {
  if (input === true || input === false) { return input }
  if (typeof input === 'string') { return input.toLowerCase() === 'true'; }
  return false
}

export function asNumber(input: string | null | undefined | number): number {
  if (typeof input === 'number') { return input }
  if (typeof input === 'string') { return parseInt(input) }
  return 0
}

export function toEditedJob(input: Job | EditedJob): EditedJob {
  return {
    id: input.id,
    name: input.name,
    gulag_id: 'gulag' in input ? input.gulag : input.gulag_id,
    credits: asNumber(input.credits),
    plan_ahead: asNumber(input.plan_ahead),
    active: asBool(input.active),
    frequency: asNumber(input.frequency),
    frequency_unit: input.frequency_unit,
    season_begins: input.season_begins,
    season_ends: input.season_ends,
    weekday_1_monday: asBool(input.weekday_1_monday),
    weekday_2_tuesday: asBool(input.weekday_2_tuesday),
    weekday_3_wednesday: asBool(input.weekday_3_wednesday),
    weekday_4_thursday: asBool(input.weekday_4_thursday),
    weekday_5_friday: asBool(input.weekday_5_friday),
    weekday_6_saturday: asBool(input.weekday_6_saturday),
    weekday_7_sunday: asBool(input.weekday_7_sunday)
  }
}

export interface JobPart {
  id: number
  job: number
  name: string
}

export interface User {
  id: number
  username: string
  first_name: string
  last_name: string
}

export interface Resolution {
  id: number
  name: string
  streak_protected: boolean
  credits_awarded: boolean
}

export interface Commendation {
  id: number,
  task: number,
  note: string,
  given_by: number
}

export interface Notification {
  id: number
  title: string
  content: string
  sentence: number
}
