import { useContext, useState, useEffect } from 'react'
import Collapse from '@mui/material/Collapse';
import { useGetOpenTasksQuery, useGetRecentlyResolvedTasksQuery} from '../../services/gulag'
import { ViewsEnum } from './types'
import { Task, Sentence } from '../../types'
import GulagContext from '../../context/gulag'
import TaskCard from './TaskCard'
import SelfSentenceContext from '../../context/selfSentence'
import { TransitionGroup } from 'react-transition-group'
import {List, ListItemButton} from '@mui/material'

interface TaskListProps {
  view: ViewsEnum
}

function byDueDate(taskA: Task, taskB: Task) {
  if (taskA == null || taskB == null) { return 0 }
  return taskA.due > taskB.due ? 1 : -1
}

function byCompletedDate(taskA: Task, taskB: Task) {
  if (taskA == null || taskB == null) { return 0 }
  return (taskA.completed || 0) > (taskB.completed || 0) ? -1 : 1
}

function getTasks(allTasks: Task[], sentence: Sentence, view: ViewsEnum) {
  if (view === ViewsEnum.MyOpen) {
    return allTasks
      .filter(({ sentence: taskSentence }) => taskSentence == sentence.id )
      .filter(({ resolution }) => resolution === null)
      .sort(byDueDate);
  } else if (view === ViewsEnum.AllOpen) {
    return allTasks
      .filter(({ resolution }) => resolution === null)
      .sort(byDueDate);
  } else if (view === ViewsEnum.RecentlyClosed) {
    return allTasks
      .filter(({ resolution }) => resolution !== null)
      .sort(byCompletedDate);
  }
}

export default function TaskList({ view }: TaskListProps) {
  const selfSentence = useContext(SelfSentenceContext);
  const [ resultCount, setResultCount] = useState<number>(10)
  useEffect(() => {
    setResultCount(10)
  }, [view, setResultCount])
  if (selfSentence === null) { throw new Error("Sentence is null")}
  const {data: openTasks = []} = useGetOpenTasksQuery(selfSentence.gulag, {
    pollingInterval: 30000,
  })
  const {data: recentlyResolvedTasks = []} = useGetRecentlyResolvedTasksQuery(selfSentence.gulag, {
    pollingInterval: 30000,
  })
  const tasks = getTasks([...openTasks, ...recentlyResolvedTasks], selfSentence, view)
  if (!tasks) { return <p>No tasks.</p>}
  return (
    <List sx={{ marginTop: 0, paddingTop: 0 }}>
      <TransitionGroup className="todo-list">
        {tasks.filter((_, idx) => idx < resultCount).map(task => (
          <Collapse key={task.id}>
            <TaskCard task={task} />
          </Collapse>
        ))}
      </TransitionGroup>
      {tasks.length > resultCount && <ListItemButton sx={{justifyContent: 'center', }} onClick={() => setResultCount(resultCount + 10)}>Show more</ListItemButton>}
    </List>
  )
}
