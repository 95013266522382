import {
  CSSTransition
} from 'react-transition-group'
import {
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  Box,
  ListItemIcon
} from '@mui/material'
import { useContext } from 'react'
import useDateTime from '../../utils/useDateTime'
import TaskContext from '../../context/task'
import NavigationContext from '../../context/navigation'
import TaskUnpack from '../../context/lookups/TaskUnpack'
import { useGetJobsQuery } from '../../services/gulag'
import { Task } from '../../types'
import JobName from '../../snippets/job/JobName'
import TaskStatus from '../../snippets/task/TaskStatus'
import TaskIcon from '../../snippets/task/TaskIcon'
import UserName from '../../snippets/user/UserName'

interface TaskCardProps {
  task: Task
}

export default function TaskCard({task}: TaskCardProps) {
  const { location, setLocation } = useContext(NavigationContext)
  const { relative: assignedRelative } = useDateTime(task.assigned)
  const unassigned = task.resolution === null && assignedRelative.valueOf() > 0
  return (
    <TaskContext.Provider value={task}>
      <ListItemButton
       sx={{ opacity: unassigned ? 0.5 : 1 }}
        alignItems="flex-start"
        onClick={() => setLocation({...location, taskId: task.id})}
      >
        <TaskUnpack>
          <ListItemIcon>
            <TaskIcon />
          </ListItemIcon>
          <ListItemText
            primary={<JobName />}
            secondary={
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  <UserName />
                </Typography>
                <TaskStatus />
              </Box>
            }
          />
        </TaskUnpack>
      </ListItemButton>
      <Divider component="li" />
    </TaskContext.Provider>
  )
}
