import { useContext } from 'react'
import JobContext from '../job'
import GulagContext from '../gulag'
import { useGetJobsQuery } from '../../services/gulag'

interface JobByIdProps {
  id: number | null
  children: JSX.Element | JSX.Element[] | null
}

export default function JobById({ id, children }: JobByIdProps) {
  const gulag = useContext(GulagContext)
  if (gulag === null) { throw new Error('Cannot use outside gulag context') }
  const { data: jobs = [], isLoading } = useGetJobsQuery(gulag.id, { pollingInterval: 300000 })
  const job = jobs.find(({ id: jobId }) => jobId === id)
  return (
    <JobContext.Provider value={job || null}>
      {children}
    </JobContext.Provider>
  )
}
