import { useContext } from 'react'

import ResolutionsContext from '../resolutions'
import ResolutionContext from '../resolution'
import TaskContext from '../task'

interface TaskToResolutionProps {
  children: JSX.Element | JSX.Element[] | null
}

export default function TaskToResolution({ children }: TaskToResolutionProps) {
  const task = useContext(TaskContext);
  const resolutions = useContext(ResolutionsContext);

  if (task === null || resolutions === null) {
    return (
      <ResolutionContext.Provider value={null}>
        {children}
      </ResolutionContext.Provider>
    )
  }
  const resolution = resolutions.find(({ id }) => id === task.resolution)
  return (
    <ResolutionContext.Provider value={resolution || null}>
      {children}
    </ResolutionContext.Provider>
  )
}
