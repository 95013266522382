import { useContext } from 'react'

import { Box } from '@mui/material'

import GulagContext from '../../context/gulag'
import NavigationContext from '../../context/navigation'
import GulagToResolutions from '../../context/lookups/GulagToResolutions'
import TaskListTabs from '../task-list/TaskListTabs'
import TaskView from '../task-view/TaskView'
import TaskById from '../../context/lookups/TaskById'


export default function GulagRoot() {
  const gulag = useContext(GulagContext);
  const { location } = useContext(NavigationContext)
  if (gulag === null) { return null }
  return (
    <GulagToResolutions>
      <Box
        component="main"
        sx={{ width: '100%' }}
      >
        <TaskListTabs />
        <TaskById id={location.taskId}>
          <TaskView />
        </TaskById>
      </Box>
    </GulagToResolutions>
  )
}
