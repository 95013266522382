import { IconButton, TextField, List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { useContext, useState } from 'react'
import SelfSentenceContext from '../../context/selfSentence'
import CommendationsContext from '../../context/commendations'
import SentenceById from '../../context/lookups/SentenceById'
import SentenceToUser from '../../context/lookups/SentenceToUser'
import TaskContext from '../../context/task'
import ResolutionsContext from '../../context/resolutions'
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import {
  useAddTaskCommendationMutation
} from '../../services/gulag'
import { Commendation } from '../../types'
import UserName from '../../snippets/user/UserName'

function AddNewAward() {
  const task = useContext(TaskContext)
  const selfSentence = useContext(SelfSentenceContext)
  const [add, addStatus] = useAddTaskCommendationMutation()
  const [newAwardName, setNewAwardName] = useState('')
  if (!task || !selfSentence) { return null }
  if (task.sentence === selfSentence.id) { return null }
  if (task.resolution === null) { return null }
  return (
    <TextField
      placeholder="Give an award"
      value={newAwardName}
      onChange={e => setNewAwardName(e.target.value)}
      fullWidth
      InputProps={{
        endAdornment: (
          <IconButton
            disabled={newAwardName === ''}
            onClick={() => {
              add({ note: newAwardName, given_by_id: selfSentence.id, task_id: task.id })
              setNewAwardName('')
            }}
            color="success"
          >
            <MilitaryTechOutlinedIcon />
          </IconButton>
        )
      }}
    />
  )
}

function Award({ value }: { value: Commendation }) {
  return (
    <ListItem disableGutters>
      <ListItemIcon>
        <MilitaryTechOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary={value.note} secondary={<>from <UserName /></>}/>
    </ListItem>
  )
}

export default function AwardManager() {
  const commendations = useContext(CommendationsContext)
  return (
    <>
      <List>
        {(commendations || []).map((commendation) => (
          <SentenceById key={commendation.id} id={commendation.given_by}>
            <SentenceToUser>
              <Award value={commendation} />
            </SentenceToUser>
          </SentenceById>
        ))}
      </List>
      <AddNewAward />
    </>
  )
}
