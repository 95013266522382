import {
  Typography,
} from '@mui/material'

export default function JobExemptionsManager() {
  return (
    <>
      <br />
      <Typography variant="h6">Job exemptions</Typography>
    </>
  )
}
