import {
  Dialog,
  DialogTitle,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Badge,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useContext } from 'react';
import CabinIcon from '@mui/icons-material/Cabin';
import { blue } from '@mui/material/colors';

import { useGetSentencesForSelfQuery, useGetGulagsQuery } from '../../services/gulag';
import { Sentence, Gulag } from '../../types';
import GulagContext from '../../context/gulag'
import NavigationContext from '../../context/navigation'

export interface GulagAndSentence {
  gulag: Gulag
  sentence: Sentence
}


interface SentenceOptionProps {
  handleListItemClick: (gulagAndSentence: GulagAndSentence) => void
  sentence: Sentence
}

function SentenceOption({ handleListItemClick, sentence }: SentenceOptionProps) {
  const { data: gulags = [], isLoading } = useGetGulagsQuery(null, { pollingInterval: 300000 })
  const currentGulag = useContext(GulagContext);
  const gulag = gulags.find(({ id }) => id === sentence.gulag)
  if (gulag === null || gulag === undefined) { return <li>Error</li> }
  let credits = sentence.total_credits.toString()
  if (sentence.total_credits >= 1000) { credits = `${(sentence.total_credits/1000).toFixed(1)}K`}
  if (sentence.total_credits >= 10000) { credits = `${Math.floor(sentence.total_credits/1000)}K`}
  return (
    <ListItem
      selected={currentGulag !== null && currentGulag.id === sentence.gulag}
      button onClick={() => handleListItemClick({ gulag, sentence })} key={sentence.id}>
      <ListItemAvatar>
        <Badge badgeContent={<>{credits}</>} color="primary">
          <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
            <CabinIcon />
          </Avatar>
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={gulag.name} secondary={gulag.location} />
    </ListItem>
  )
}

interface GulagSelectorDialogProps {
  handleClose: () => void
  handleListItemClick: (gulagAndSentence: GulagAndSentence) => void
  sentences: Sentence[]
}

function GulagSelectorDialog({ sentences, handleClose, handleListItemClick }: GulagSelectorDialogProps) {
  const open = true;
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <List
      sx={{ pt: 0, mb: 0, mt: 0, pb: 0 }}
      component="nav"
      subheader={
        <ListSubheader>
          Switch gulag
        </ListSubheader>
      }
    >
      {sentences.map((sentence) => (
        <SentenceOption
          sentence={sentence}
          key={sentence.id}
          handleListItemClick={handleListItemClick}
        />
      ))}
    </List>
  )
}

interface DrawerGulagSelectorProps {
  selectGulag: (gulagAndSentence: GulagAndSentence) => void
}

export default function DrawerGulagSelector({ selectGulag }: DrawerGulagSelectorProps) {
  const { data: sentences = [], isLoading } = useGetSentencesForSelfQuery(null, { pollingInterval: 300000 });
  const gulag = useContext(GulagContext);
  return (
    <GulagSelectorDialog
      sentences={sentences}
      handleClose={() => {}}
      handleListItemClick={selectGulag}
    />
  )
}
